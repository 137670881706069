
import React, { useEffect, useState } from "react";
import LogoResp from "./../img/logos/logo_iman_full.png";
import LogoRespSmall from "./../img/logos/logo_iman.png";
import Balance from "../components/caisse"
import { useAlert } from "../contexts/alertContext";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from "@mdi/react";
import { mdiMenuClose } from "@mdi/js";


export default function PageHeader({ defaultPath, dynamicComponentForPath }) {
    const { noMenu, handleNoMenu, hideMenu, handleHideMenu } = useAlert()
    const size = useWindowSize();
    const Gfunc = require("../Gfunc");

    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
            width: window.innerWidth,
            height: window.innerHeight,
        });

        useEffect(() => {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);

        return windowSize;
    }
    return (
        (size.width <= Gfunc.mediumScreen) ? <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" style={{ borderRadius: "0px 0px 0px 0px", background: "white", color: "black", marginBottom: "4px", height: "45px", padding: "0" }} elevation={4}>
                <Toolbar sx={{ minHeight: "0px !important", height: "100%" }}>
                    {(noMenu || size.width <= Gfunc.smallScreen) && <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => handleHideMenu(false)}
                        style={{ padding: "4px", background: "#efefef", marginLeft: "-6px", marginRight: "8px" }}
                    >
                        <Icon path={mdiMenuClose} size="30px" color={"gray"} />
                    </IconButton>}
                    <div id="header">
                        {defaultPath && (
                            <div style={{ width: "100%" }}>
                                <div className="line-container">
                                    {(size.width >= Gfunc.smallScreen) && <>
                                        <span className="pageTitleElem">
                                            {dynamicComponentForPath?.parent}
                                        </span>
                                        <div className="horizontal-line"></div>
                                    </>}
                                    <span className="pageTitleElem">
                                        {dynamicComponentForPath?.itself}
                                    </span>
                                    <div className="horizontal-line"></div>
                                    <div className="pageTitleElemMain">
                                        <span className="pageTitleElem">
                                            {dynamicComponentForPath?.description}
                                        </span>
                                    </div>
                                </div>
                                <Balance small={true} />
                            </div>)}</div>
                    <div id="disconnect" >
                        {(size.width > 460) ? <img
                            src={LogoResp}
                            alt="logo"
                            style={{ height: "30px", marginRight: "0px" }}
                        /> : <img
                            src={LogoRespSmall}
                            alt="logo"
                            style={{ height: "30px", marginRight: "0px" }}
                        />}
                    </div>
                </Toolbar>
            </AppBar>
        </Box> : <div id="header">
            {(noMenu) && <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => handleHideMenu(false)}
                style={{ padding: "4px", background: "#efefef", marginLeft: "-6px", marginRight: "8px" }}
            >
                <Icon path={mdiMenuClose} size="30px" color={"gray"} />
            </IconButton>}
            {defaultPath && (
                <div className="line-container">
                    <span className="pageTitleElem">
                        {dynamicComponentForPath?.parent}
                    </span>
                    <div className="horizontal-line"></div>
                    <span className="pageTitleElem">
                        {dynamicComponentForPath?.itself}
                    </span>
                    <div className="horizontal-line"></div>
                    <div className="pageTitleElemMain">
                        <span className="pageTitleElem">
                            {dynamicComponentForPath?.description}
                        </span>
                    </div>
                </div>
            )}

            <div id="disconnect" >
                <Balance />
                <img
                    src={LogoResp}
                    alt="logo"
                    style={{ height: "45px", marginRight: "16px" }}
                />
            </div>
        </div>
    );
}


