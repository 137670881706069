import React, { useEffect } from "react";
import { Tooltip, Typography } from "@mui/material";
import { ReactComponent as Reste } from "../img/icons/le reste.svg";

function Usr_Caisse({ small }) {
    const Gfunc = require("../Gfunc.js");


    useEffect(() => {

        Gfunc.fetchCaisse(localStorage.getItem("uid"));

    }, [Gfunc]);

    const handleCaisse = () => {
        Gfunc.fetchCaisse(localStorage.getItem("uid"));
    };

    return (
        (!small) ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", cursor: "pointer" }} onClick={handleCaisse} >
            <Tooltip title="Balance" arrow disableInteractive>
                <Reste style={{ width: "18px", height: "18px", fill: "#012244", marginTop: "4px", marginRight: "8px" }} /></Tooltip>
            <Typography id="userBalance" style={{ fontSize: "12px !important", color: "#012244" }}>
            </Typography>
        </div> : <div style={{ left: "50%", transform: "translateX(-50%)", position: "absolute", top: "45px", display: "flex", alignItems: "center", width: "fit-content", height: "fit-content", cursor: "pointer", background: "#012244", padding: "0px 8px", borderRadius: "0 0 10px 10px" }} onClick={handleCaisse} >
            <Tooltip title="Balance" arrow disableInteractive>
                <Reste style={{ width: "16px", height: "16px", fill: "white", marginTop: "4px", marginRight: "8px", }} /></Tooltip>
            <Typography id="userBalance" style={{ color: "white" }} fontSize={"11px"}>
            </Typography>
            </div>
    );
}

export default React.memo(Usr_Caisse);
